import { Location } from '@reach/router';
import { Link } from 'gatsby';
import React from 'react';
import footerStyles from './footer.module.css';

const Footer: React.SFC = () => (
  <Location>
    {({ location }) => {
      if (location.search && location.search.indexOf('nofooter') >= 0) {
        return null;
      }
      return (
        <div className={footerStyles.container}>
          <div className={footerStyles.content}>
            <Link to="/" className={footerStyles.link}>
              {`© Swapbox ${new Date().getFullYear()}`}
            </Link>
            <span>
              <Link to="/ru/privacy_policy" className={footerStyles.link}>
                Политика конфиденциальности
              </Link>
            </span>
            <span>
              <Link to="/en/terms_of_use" className={footerStyles.link}>
                Пользовательское соглашение
              </Link>
            </span>
            <span>
              <Link to="/contacts" className={footerStyles.link}>
                Контакты
              </Link>
            </span>
          </div>
        </div>
      );
    }}
  </Location>
);

export default Footer;
