import { graphql, StaticQuery } from 'gatsby';
import React from 'react';
import Helmet from 'react-helmet';

import Footer from './Footer';
import './layout.css';
import layoutStyles from './layout.module.css';

const HEADER_QUERY = graphql`
  query SiteTitleQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`;

const Layout: React.SFC = ({ children }) => (
  <StaticQuery query={HEADER_QUERY}>
    {(data) => (
      <React.Fragment>
        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            { name: 'description', content: data.site.siteMetadata.title },
            { name: 'keywords', content: 'обмен, мобильное приложение, swapp' },
          ]}
        >
          <html lang="ru" />
        </Helmet>
        <div className={layoutStyles.content}>{children}</div>
        <Footer siteTitle={data.site.siteMetadata.title} />
      </React.Fragment>
    )}
  </StaticQuery>
);

export default Layout;
